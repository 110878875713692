import { Area, BannerType, Languages } from '@/constants'
import { useLocalStorage } from '@/hooks'
import { useCookie } from '@/hooks/useCookie'
import { useCookie1Y } from '@/hooks/useCookie1Y'
import type {
  BannerList,
  Keywords,
  Menu,
  ProductCompare,
  ProductViewed,
  Province,
  QueryParam,
  Region,
  SystemConfig,
} from '@/types'
import { api } from '@/utils'
import { convertQueryParam } from '@/utils/next-util'
import constate from 'constate'
import { getCookie } from 'cookies-next'
import { useEffect, useMemo, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

type Props = {
  stores: Province[] | null
  regions: Region[]
  area: string | null
  language: Languages
  systemConfig: SystemConfig
  menus: Menu[] | null
  quickMenu: Menu[] | null
  bottomMenu: Menu[] | null
  banner: BannerList[] | null
  keywords: Keywords[] | null
}

const StoreContainer = (props: Props) => {
  const [isOpenPopupLocation, setIsOpenPopupLocation] = useState(false)
  const [isDisplayAreaPopup, setIsDisplayAreaPopup] = useLocalStorage<boolean>(
    'areaPopup',
    true
  )
  const currentRegion = getCookie('Area-Code')
  const defaultSaleRegion = process.env.NEXT_PUBLIC_SALE_REGION_DEFAULT
  const [productCompares, setProductCompares] = useLocalStorageState<
    ProductCompare[]
  >('productCompare', { defaultValue: [{}, {}, {}] })

  const [productViewed, setProductViewed] = useLocalStorage<ProductViewed[]>(
    'productViewed',
    []
  )
  const [isOpenComparePopup, setIsOpenComparePopup] = useState(false)
  const [isOpenAddComparePopup, setIsOpenAddComparePopup] = useState(false)
  const [isOpenComingSoon, setIsOpenComingSoon] = useState(false)
  const [catViewId, setCatViewId] = useState<string | null>(null)
  const [stores, setStores] = useState<Province[]>([])
  const [regions, setRegions] = useState<Region[]>([])
  const [megaMenu, setMegaMenu] = useState<Menu[]>(props.menus || [])
  const [quickMenu, setQuickMenu] = useState<Menu[]>(props.quickMenu || [])
  const [bottomMenu] = useState<Menu[]>(props.bottomMenu || [])
  const [banner, setBanner] = useState<BannerList[]>(props.banner || [])
  const [province, setProvince] = useCookie<string | null>('province', null)
  const [destinationCode, setDestinationCode] = useCookie<string | null>(
    'destinationCode',
    null
  )
  const [isPreOrder, setIsPreOrder] = useCookie<string | null>(
    'isPreOrder',
    null
  )
  const [keywords, setKeywords] = useState<Keywords[]>(props.keywords || [])
  const [provinces, setProvinces] = useState<Province[]>([])
  const [provinceAvailable, setProvinceAvailable] = useState<Province[]>([])
  const [provinceCode, setProvinceCode] = useState<string>('')
  const [districts, setDistricts] = useState<Province[]>([])
  const [districtCode, setDistrictCode] = useState<string>('')
  const [wardCode, setWardCode] = useState<string>('')
  const [wardDistricts, setWardDistricts] = useState<Province[]>([])
  const [wards, setWards] = useState<Province[]>([])
  const [areasError, setAreasError] = useState('')
  const [areasLoading, setAreasLoading] = useState(true)
  const [provinceOfRegion, setProvinceOfRegion] = useState<Region[] | null>(
    null
  )
  const [regionChange, setRegionChange] = useState<string>('')
  const [selectedLang, setSelectedLang] = useCookie<
    Languages | undefined | null
  >('language', props.language || Languages.VN)
  const queryParams = { level: Area.PROVINCE }
  const [area, setArea] = useCookie1Y<string | null>('area', null)
  const [areaId, setAreaId] = useCookie1Y<string | null>('area-id', null)

  const [saleRegion, setSaleRegion] = useCookie1Y<string | null>(
    'Area-Code',
    null
  )

  const [isChangeRegionModal, setIsChangeRegionModal] = useState(false)
  useEffect(() => {
    const checkShowArea = localStorage.getItem('Area-Code')
    const checkHeaderRegion = getCookie('Area-Code')

    if (checkShowArea !== 'true' && !checkHeaderRegion) {
      setIsOpenPopupLocation(true)
    }
  }, [])
  useEffect(() => {
    if (regions) {
      const defaultStore = regions.find(e => e?.code == defaultSaleRegion)
      if (defaultStore && defaultSaleRegion && !saleRegion) {
        setSaleRegion(defaultStore?.code)
      }
    }
  }, [regions])

  useEffect(() => {
    if (props.menus) {
      setMegaMenu(props.menus)
    }
  }, [props.menus])

  useEffect(() => {
    if (props.quickMenu && !quickMenu) {
      setQuickMenu(props.quickMenu)
    }
  }, [props.quickMenu])
  useEffect(() => {
    if (props.keywords && keywords.length == 0) {
      setKeywords(props.keywords)
    }
  }, [props.keywords])

  useEffect(() => {
    if (props.banner) {
      setBanner(props.banner)
    }
  }, [props.banner])

  useEffect(() => {
    if (props.regions) {
      setRegions(props.regions)
    }
  }, [props.regions])

  useEffect(() => {
    if (props.language && !selectedLang) {
      setSelectedLang(props.language)
    }
  }, [props.language])

  const topBanner = useMemo(() => {
    const topBanner = banner.find(e => e.type === BannerType.TOP_BAR)
    return topBanner
  }, [banner])

  const sideBarDataBanner = useMemo(() => {
    const sideBarDataBanner = banner.find(e => e.type === BannerType.SIDEBAR)
    return sideBarDataBanner ? sideBarDataBanner.items : []
  }, [banner])

  useEffect(() => {
    api(`api/sale-region/province`, {
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      method: 'get',
    })
      .then(async result => {
        if (result instanceof Error) {
          setAreasError(result.message)
          return
        }
        setProvinceOfRegion(result.data)
      })
      .finally(() => {
        setAreasLoading(false)
      })
  }, [])

  useEffect(() => {
    api(`api/area?type=shipping&level=2`, {
      method: 'get',
      baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    }).then(async result => {
      if (result instanceof Error) {
        return
      }
      const province = result.data.filter(
        (e: { saleRegionCode: string }) => e.saleRegionCode
      )
      setProvinces(province)
    })
  }, [])

  useEffect(() => {
    if (provinceCode !== '') {
      api(
        `api/area?${convertQueryParam({
          ...queryParams,
          ...{ parentCode: provinceCode, level: Area.DISTRICT },
        } as unknown as QueryParam)}`,
        {
          method: 'get',
          baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
        }
      )
        .then(async result => {
          if (result instanceof Error) {
            setAreasError(result.message)
            return
          }
          const res = result?.data.map((e: Province) => ({
            ...e,
            ...{
              name: e.name,
            },
          }))
          setDistricts(res)
        })
        .finally(() => {
          setAreasLoading(false)
        })
      api(`api/area/${provinceCode}/ward?`, {
        method: 'get',
        baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
      })
        .then(async result => {
          if (result instanceof Error) {
            setAreasError(result.message)
            return
          }
          setWardDistricts(result?.data)
        })
        .finally(() => {
          setAreasLoading(false)
        })
    }
  }, [provinceCode])

  useEffect(() => {
    if (districtCode && districtCode !== '') {
      api(
        `api/area?${convertQueryParam({
          ...queryParams,
          ...{ parentCode: districtCode, level: Area.WARD },
        } as unknown as QueryParam)}`,
        {
          method: 'get',
          baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
        }
      )
        .then(async result => {
          if (result instanceof Error) {
            setAreasError(result.message)
            return
          }
          const res = result?.data.map((e: Province) => ({
            ...e,
            ...{
              name: e.name,
            },
          }))
          setWards(res)
        })
        .finally(() => {
          setAreasLoading(false)
        })
    }
  }, [districtCode])

  const getDistricts = (provinceCode: string) => {
    if (provinceCode) {
      setProvinceCode(provinceCode)
    }
  }

  const getWards = (districtCode: string) => {
    if (districtCode) {
      setDistrictCode(districtCode)
    }
  }
  return {
    province,
    setProvince,
    area,
    setArea,
    areaId,
    setAreaId,
    setSelectedLang,
    setDestinationCode,
    destinationCode,
    selectedLang,
    stores,
    banner,
    megaMenu,
    quickMenu,
    topBanner,
    sideBarDataBanner,
    keywords,
    setStores,
    isOpenPopupLocation,
    setIsOpenPopupLocation,
    isDisplayAreaPopup,
    bottomMenu,
    setIsDisplayAreaPopup,
    isOpenComingSoon,
    setIsOpenComingSoon,
    productCompares,
    setProductCompares,
    isOpenComparePopup,
    setIsOpenComparePopup,
    isOpenAddComparePopup,
    setIsOpenAddComparePopup,
    productViewed,
    setProductViewed,
    catViewId,
    setCatViewId,
    isPreOrder,
    setIsPreOrder,
    saleRegion,
    setSaleRegion,
    regions,
    setRegions,
    currentRegion,
    provinces,
    setProvinces,
    districts,
    setDistricts,
    wards,
    getDistricts,
    provinceCode,
    setWardDistricts,
    wardDistricts,
    getWards,
    setWards,
    setProvinceCode,
    areasError,
    areasLoading,
    districtCode,
    setWardCode,
    wardCode,
    setDistrictCode,
    provinceOfRegion,
    setProvinceOfRegion,
    provinceAvailable,
    setProvinceAvailable,
    isChangeRegionModal,
    setIsChangeRegionModal,
    regionChange,
    setRegionChange,
  }
}

export const [StoreProvider, useStore] = constate(StoreContainer)
